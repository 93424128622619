import React, { useContext, useEffect, useState } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import CardTitle from './CardTitle';
import Details from './Details';
import IVButton from '@invideoio/web-shared/components/Button';
import IVCard from '@invideoio/web-shared/components/Card';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';

import { AUTH_TYPE } from '../../constants/homepage';
import { RedirectContext, StateContext } from '../../store/store';
import {
  MakeWorkFlowCard as MakeWorkFlowCardType,
  PosterType,
} from '../../types';
import CardSubTitle from './CardSubTitle';
import {
  constructSourceSet,
  constructSourceSetSizes,
  getImageKitUrl,
} from '@utils/common.utils';
import { BrowserType } from '@invideoio/web-shared/constants/common';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';
import { BaseAppType } from './types';
import { getPosterURL } from '@invideoio/web-shared/utils/common';

interface MakeWorkFlowCardProps {
  makeWorkFlowCard: MakeWorkFlowCardType;
  browser: BrowserType | string;
  customImageContainer?: JSX.Element;
}

const MakeWorkFlowCard = (props: MakeWorkFlowCardProps) => {
  const { makeWorkFlowCard, browser, customImageContainer } = props;

  const { Title, SubTitle, Image, CtaUrl, CtaText, Content } = makeWorkFlowCard;
  const { handleClick, goToAiSignup } = useContext(RedirectContext);
  const { baseAppType } = useContext(StateContext);

  const [srcSet, setSrcSet] = useState<string>('');

  const poster: PosterType = Image.desktop || { defaultURL: Image.url };

  let imageURL = getPosterURL(poster, browser);

  useEffect(() => {
    const devicePixelRatio = window ? window.devicePixelRatio : 1;

    const desktopImageURL = getImageKitUrl(imageURL, {
      width: 800 * devicePixelRatio,
    });
    const tabletImageURL = getImageKitUrl(imageURL, {
      width: 410 * devicePixelRatio,
    });
    const mobileImageURL = getImageKitUrl(imageURL, {
      width: 380 * devicePixelRatio,
    });

    const srcSet = constructSourceSet([
      { url: mobileImageURL, intrinsicWidth: 380 * devicePixelRatio },
      { url: tabletImageURL, intrinsicWidth: 385 * devicePixelRatio },
      { url: desktopImageURL, intrinsicWidth: 800 * devicePixelRatio },
    ]);

    setSrcSet(srcSet);
  }, [imageURL]);

  const imageSizes = constructSourceSetSizes([
    { query: '(min-width: 820px)', size: 'calc((100vw - 50px) / 2)' }, // tablet + desktop
    { query: '', size: 'calc(100vw - 40px)' }, // mobile
  ]);

  const onClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'Workflow Card',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.WorkflowCard,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    });
    if (baseAppType === BaseAppType.AI) {
      if (CtaUrl) {
        if (CtaUrl.includes('ai.invideo.io')) {
          goToAiSignup({
            ux_source: ClickCtaUxSource.WorkflowCard,
            oa: new URL(CtaUrl).searchParams.get('oa'),
          });
        } else {
          window.open(CtaUrl, '_self');
        }
      } else {
        goToAiSignup({ ux_source: ClickCtaUxSource.WorkflowCard });
      }
    } else {
      handleClick(CtaUrl, {
        tab: AUTH_TYPE.SIGNUP,
        ux_source: ClickCtaUxSource.WorkflowCard,
      });
    }
  };

  return (
    <IVCard>
      <div
        className={ivclass(
          'xl:iv-grid lg:iv-grid',
          'xl:iv-grid-cols-12 lg:iv-grid-cols-12',
          'sm:iv-gap-30',
          'md:iv-flex',
          'md:iv-h-full',
          'sm:iv-flex',
          'sm:iv-flex-col',
          'sm:iv-h-full',
        )}
      >
        <div
          className={ivclass(
            'iv-flex iv-flex-col iv-justify-between iv-h-full md:iv-flex-1',
            'xl:iv-col-span-5 lg:iv-col-span-5 sm:iv-col-span-12 md:iv-flex-grow',
          )}
        >
          <div
            className={ivclass(
              'md:iv-flex md:iv-flex-col md:iv-items-start',
              'xl:iv-pl-40 lg:iv-pl-35',
              'md:iv-px-35 sm:iv-px-25',
              'xl:iv-pt-40 iv-pt-35 md:iv-pt-30 sm:iv-pt-20',
            )}
          >
            <CardTitle firstLine={Title} />
            {SubTitle && (
              <div className={ivclass('iv-mt-20 sm:iv-mt-10')}>
                <CardSubTitle subTitle={SubTitle} />
              </div>
            )}
            <div
              className={ivclass(
                'xl:iv-pt-35 lg:iv-pt-20 md:iv-pt-15 iv-pt-15',
                'iv-pb-20 md:iv-pb-15 sm:iv-pb-15 md:iv-pr-10',
                'md:iv-flex-grow',
              )}
            >
              <Details text={Content} />
            </div>
          </div>

          <div
            className={ivclass(
              'xl:iv-pl-40 iv-pl-35 sm:iv-px-20 iv-pb-40 lg:iv-pb-35 sm:iv-pb-20',
            )}
          >
            <IVButton
              className={ivclass(
                'iv-inline-flex',
                'iv-items-center',
                'iv-cursor-pointer',
                'iv-mr-[25px]',
                'xl:iv-py-20 iv-py-[14px] sm:iv-py-5 xl:iv-px-[24px] iv-px-20 sm:iv-px-[14px]',
                'iv-border-2 iv-border-blue',
                'iv-rounded-full sm:iv-rounded-m',
                'iv-text-blue',
                'xl:iv-text-body-3xl iv-text-body-2xl sm:iv-text-body-xl',
                'xl:iv-font-bold iv-font-semibold',
                'hover:iv-bg-blue hover:iv-text-grey-0',
              )}
              type="button"
              onClick={onClick}
            >
              {CtaText}
            </IVButton>
          </div>
        </div>
        <div
          className={ivclass(
            'xl:iv-col-span-7 lg:iv-col-span-7 sm:iv-col-span-12 sm:iv-order-1',
            'md:iv-flex-1',
          )}
        >
          <div
            className={ivclass(
              'iv-relative',
              'iv-flex iv-justify-end iv-h-full',
              'xl:iv-max-h-[560px] lg:iv-max-h-[480px] md:iv-max-h-[380px]',
              'iv-overflow-hidden',
            )}
          >
            {customImageContainer ? (
              customImageContainer
            ) : (
              <ImageComponent
                elementype="native"
                className="iv-h-full iv-object-cover iv-object-left iv-aspect-[1.35]"
                loading="lazy"
                src={imageURL}
                srcSet={srcSet}
                sizes={imageSizes}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </IVCard>
  );
};

export default MakeWorkFlowCard;
