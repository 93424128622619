import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import WorkFlowCard from './MakeWorkFlowCard';
import { BrowserProps, MakeWorkflow } from '../../types';
import TitleH2BlockGradient from '@components/common/Title/TitleH2BlockGradient';
import { BaseAppType } from './types';

interface WorkflowProps extends BrowserProps {
  platform: string;
  makeWorkFlow: MakeWorkflow;
}

const WorkflowV2 = (props: WorkflowProps) => {
  const { browser, makeWorkFlow } = props;

  const { title, MakeWorkflowCard } = makeWorkFlow;

  return (
    <section
      id="iv-workflow"
      className={ivclass(
        'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
        'xl:iv-mt-200 iv-mt-150 sm:iv-mt-100',
        'iv-grid, iv-grid-cols-12',
      )}
    >
      <div
        className={ivclass(
          'xl:iv-mb-75 iv-mb-50 sm:iv-mb-30',
          'sm:iv-px-5',
          'iv-grid iv-col-span-12 iv-col-start-2',
        )}
      >
        <TitleH2BlockGradient heading={title} />
      </div>

      {Array.isArray(MakeWorkflowCard) && (
        <div className={ivclass()}>
          <div
            className={ivclass(
              'iv-grid',
              'iv-gap-40 md:iv-gap-25 sm:iv-gap-25',
              'iv-grid-cols-1',
            )}
          >
            {MakeWorkflowCard.map(card => (
              <WorkFlowCard
                makeWorkFlowCard={card}
                key={card.id}
                browser={browser}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default WorkflowV2;
