import React, { useState, forwardRef } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import { AssetsAppName, getWebAssetsUrl } from '@utils/web-assets';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface VideoProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  loading?: 'preload' | 'eager' | 'lazy';
  isVideoWall?: boolean;
}

const VideoComponentWithVolumeControls = forwardRef<
  HTMLVideoElement,
  VideoProps
>((props, ref) => {
  const { loading, children, isVideoWall, ...videoProps } = props;
  const [isMuted, setIsMuted] = useState(!!videoProps.muted);
  const assetsUrl = process.env.NEXT_PUBLIC_EDITOR_CDN_URL;
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  if (loading === 'preload') {
    <Head>
      <link
        key={'__ivideo-' + videoProps.src}
        rel="preload"
        as="video"
        href={videoProps.src}
      ></link>
    </Head>;
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <video ref={ref} {...videoProps} muted={isMuted}>
        {children}
      </video>
      <button
        onClick={toggleMute}
        className={ivclass(
          'iv-absolute',
          isVideoWall
            ? 'iv-top-[10px] iv-left-[10px]'
            : 'iv-top-[30px] iv-left-[30px]',
          'iv-opacity-30 hover:iv-opacity-100',
          'iv-bg-grey-0',
          'iv-border-solid iv-border-grey-85',
          'iv-rounded-full',
          isVideoWall ? 'iv-w-[45px] iv-h-[45px]' : 'iv-w-[65px] iv-h-[65px]',
          'iv-flex iv-items-center iv-justify-center',
          'iv-shadow-md',
          'iv-cursor-pointer',
          'iv-transition-all iv-duration-500 iv-ease-in-out',
          isVideoWall ? 'hover:iv-scale-100' : 'hover:iv-scale-130',
        )}
        style={{
          border: '5px solid rgba(133, 133, 133, 0.2)',
        }}
        onMouseOver={e => {
          e.currentTarget.style.opacity = '1';
          e.currentTarget.style.transform = isVideoWall
            ? 'scale(1)'
            : 'scale(1.3)';
        }}
        onMouseOut={e => {
          e.currentTarget.style.opacity = '0.3';
          e.currentTarget.style.transform = 'scale(1)';
        }}
      >
        {isMuted ? (
          <Image
            width={isVideoWall ? 20 : 30}
            height={isVideoWall ? 20 : 30}
            src={
              assetsUrl +
              '/dark/panels/top-panel/component-props/selected/volume-mute.svg'
            }
            alt="Mute"
          />
        ) : (
          <Image
            width={isVideoWall ? 20 : 30}
            height={isVideoWall ? 20 : 30}
            src={
              assetsUrl +
              '/dark/panels/top-panel/component-props/selected/volume.svg'
            }
            alt="Unmute"
          />
        )}
      </button>
    </div>
  );
});

VideoComponentWithVolumeControls.displayName =
  'VideoComponentWithVolumeControls';

export default VideoComponentWithVolumeControls;
