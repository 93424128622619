import React, {
  forwardRef,
  PropsWithChildren,
  VideoHTMLAttributes,
} from 'react';
import Head from 'next/head';

interface VideoProps
  extends PropsWithChildren<VideoHTMLAttributes<HTMLVideoElement>> {
  loading?: 'lazy' | 'preload';
}

const VideoComponent = forwardRef<HTMLVideoElement, VideoProps>(
  (props, ref) => {
    const { loading, children, ...videoProps } = props;

    if (loading === 'preload') {
      <Head>
        <link
          key={'__ivideo-' + videoProps.src}
          rel="preload"
          as="video"
          href={videoProps.src}
        ></link>
      </Head>;
    }

    return (
      <video ref={ref} {...videoProps}>
        {children}
      </video>
    );
  },
);

VideoComponent.displayName = 'VideoComponent';

export default VideoComponent;
