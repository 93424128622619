import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface DetailsProp {
  text: string;
}

const Details = ({ text }: DetailsProp) => {
  return (
    <div
      className={ivclass(
        'iv-text-black-50',
        'xl:iv-text-heading-4xs iv-text-body-3xl sm:iv-text-body-xl',
        'xl:iv-m-heading-4xs lg:iv-m-body-3xl sm:iv-m-body-xl',
        'iv-font-semibold',
        'iv-whitespace-pre-wrap',
      )}
    >
      {text}
    </div>
  );
};

export default Details;
