import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React from 'react';

interface CardSubTitleProps {
  subTitle?: string;
}

function CardSubTitle({ subTitle }: CardSubTitleProps) {
  return (
    <div
      className={ivclass(
        'iv-text-black-50',
        'xl:iv-text-heading-3xs iv-text-body-3xl sm:iv-text-body-xl',
        'xl:iv-m-heading-3xs iv-m-body-3xl sm:iv-m-body-xl',
        'iv-font-semibold',
      )}
    >
      {subTitle}
    </div>
  );
}

export default CardSubTitle;
