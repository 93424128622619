export type TemplateResponseStoryType = {
  preview_url: string;
  thumbnail_url: string;
  website_thumbnail_url: string;
};

export interface TemplateResponseType {
  template_id: number;
  name: string;
  preview_list: {
    story: {
      [key: string]: TemplateResponseStoryType;
    };
  };
}

export interface TemplateType {
  id: number;
  name: string;
  previewURL: string;
  thumbnailURL: string;
}

export interface TemplateProps extends TemplateType {
  templateURL: string;
}

export interface TemplatesProps {
  platform: string;
}

export type UserRole = 'admin' | 'editor';

export interface TeamInfoType {
  name: string;
  role: UserRole;
  inviter?: string;
  invite_link?: string;
  email_id?: string;
  intent?: 'review';
  metadata?: { video_id: number };
}

interface InvitedTeamInfo extends TeamInfoType {
  status: boolean;
  id: string;
  existing_user?: boolean;
  intent?: 'review';
  metadata?: { video_id: number };
}

export interface InviteResponseType {
  data: InvitedTeamInfo;
  error_code: string;
  message: string;
}

export interface TeamInviteInfoType extends InvitedTeamInfo {
  error_code: string;
  message: string;
}

export enum BaseAppType {
  AI = 'AI',
  STUDIO = 'STUDIO',
}
