import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface TitleProp {
  firstLine: string;
}

const CardTitle = ({
  firstLine,
  children,
}: React.PropsWithChildren<TitleProp>) => {
  return (
    <h3
      className={ivclass(
        'iv-text-grey-100',
        'xl:iv-text-heading-s iv-text-heading-2xs sm:iv-text-heading-4xs',
        'xl:iv-m-heading-s iv-m-heading-2xs sm:iv-m-heading-4xs',
        'iv-font-extrabold',
      )}
    >
      <span className={ivclass('xl:iv-block lg:iv-block')}>{firstLine}</span>
      {children}
    </h3>
  );
};

export default CardTitle;
